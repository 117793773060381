





































import Component from "vue-class-component";
import Vue from "vue";
import Selector from "@/components/Selector.vue";
import SvgIcon from "@/components/general/SvgIcon.vue";
import { headerLinks } from "@/collections/headerLinks";

@Component({
  components: {
    Selector,
    SvgIcon,
  },
})
export default class Header extends Vue {
  checked: boolean = false;
  headerLinks: Record<string, any> = [];
  created() {
    this.headerLinks = headerLinks;
  }
  goToUrl(url: string, filter: string) {
    this.$router
      .push({
        name: url,
        params: { filter: filter },
      })
      .catch(() => {});
  }
}
