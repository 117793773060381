










import Vue from 'vue';
import Component from 'vue-class-component';
import Layout from './components/layout/Layout.vue'
@Component({
    components: {
      Layout
    }
})
export default class App extends Vue {

}
