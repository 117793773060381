







import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';
@Component({})
export default class SvgIcon extends Vue {
    @Prop() styles?: String; 
    @Prop() name!: String;
    @Prop() externalIcon?: String;
}
