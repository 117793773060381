<template>
    <div id="top-button" @click="scrollTop" v-show="visible" >
        <slot></slot>
    </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false
    }
  },
  methods: {
    scrollTop: function () {
     /* this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 300)
      }, 50)
      */
     window.scroll(0, 0);
    },
    scrollListener: function () {
      this.visible = window.scrollY > 150
    }
    
  },
  mounted: function () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.scrollListener)
  }
}
</script>

