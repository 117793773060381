export enum BookFilters {
  ALL='all',
  PUBLISHED='published',
  INEDIT='inedit'
}

export enum GalleryFilters{
  TRAVELS='4',
  FAMILY='2',
  UNIVERSITY='3',
  TRIBUTE='1',
  AWARDS='6',
  OTHERS='5'
}