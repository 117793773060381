

















import Footer from "./general/footer.vue";
import Header from "./general/header.vue";
import Component from "vue-class-component";
import ScrollTopButton from "@/components/general/ScrollTopButton.vue";
import SvgIcon from "@/components/general/SvgIcon.vue";
import Vue from "vue";

@Component({
components: {
    Footer,
    Header,
    ScrollTopButton,
    SvgIcon,
},
})
export default class Layout extends Vue {}
