





















import Component from 'vue-class-component';
import Vue from 'vue';
import SvgIcon from '@/components/general/SvgIcon.vue';
@Component({
    components:{
        SvgIcon
    }
})
export default class Footer extends Vue {
   
}
